import glue from '@smartfire/glue-ui';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import localizedFormat from 'dayjs/plugin/localizedFormat';

// Glue Project Configuration
glue.setFrontAvailableTranslations(['fr']);
glue.setEditingAvailableTranslations(['fr']);
glue.setSmartFormStatus(true);

dayjs.extend(localizedFormat);

dayjs.locale('fr');
