import './config';
import './theme.scss';
import ReactDOM from 'react-dom';
import glue from '@smartfire/glue-ui';
import { websitesBlocksLoader, websitesLoader } from './websites';

const origin = window.location.host;

(async function () {
    try {
        const data = await (await fetch('/websites.json')).json();
        const website = Object.keys(websitesLoader).find((key) => {
            return data[key] === origin;
        });
        if (website) {
            glue.api.interceptors.request.use((config) => {
                if (website !== 'ADMIN') {
                    config.params = { ...config.params, website: website };
                }
                return config;
            });
            if (website !== 'ADMIN') {
                await websitesBlocksLoader[website]();
            }
            await websitesLoader[website]();
            console.log('website loaded');
        } else {
            ReactDOM.render(
                <div>404 Not Found - Please select one website from list</div>,
                document.getElementById('root'),
            );
        }
    } catch (e) {
        console.error(e);
        console.error('unable to load website');
    }
})();
