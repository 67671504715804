import type { Website } from '@smartfire/glue-ui';
import { colors, PaletteOptions } from '@mui/material';

export const websitesLoader = {
    EASY_VO: () => import(/* webpackChunkName: 'easyvo' */ './easyvo_website'),
    CAR_STORE: () => import(/* webpackChunkName: 'carstore' */ './carstore_website'),
    ADMIN: () => import(/* webpackChunkName: 'admin-ui' */ './admin'),
};

export const websitesBlocksLoader = {
    EASY_VO: () => import(/* webpackChunkName: 'easyvo-blocks' */ './easyvo_website/blocks'),
    CAR_STORE: () => import(/* webpackChunkName: 'carstore-blocks' */ './carstore_website/blocks'),
};

export const layoutBlocksLoader = {
    EASY_VO: () => import(/* webpackChunkName: 'easyvo-layouts' */ './easyvo_website/layouts'),
    CAR_STORE: () => import(/* webpackChunkName: 'carstore-layouts' */ './carstore_website/layouts'),
};

export const componentsLoader = {
    EASY_VO: () => import(/* webpackChunkName: 'easyvo-components' */ './easyvo_website/components'),
    CAR_STORE: () => import(/* webpackChunkName: 'carstore-components' */ './carstore_website/components'),
};

export const websites: Website[] = [
    {
        name: 'Easy VO',
        code: 'EASY_VO',
        bodyClass: 'easyvo-website',
    },
    {
        name: 'Carstore',
        code: 'CAR_STORE',
        bodyClass: 'carstore-website',
    },
];

const white = '#FFFFFF';

const basePalette: PaletteOptions = {
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400],
    },
    text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
    },
    background: {
        default: '#F4F6F8',
        paper: '#FFF',
    },
    divider: colors.grey[200],
};

export const themeColors = {
    EASY_VO: {
        ...basePalette,
        primary: {
            light: '#77849f',
            main: '#2d374b',
            dark: '#222938',
            contrastText: '#fff',
        },
        secondary: {
            light: '#E30714',
            main: '#E30714',
            dark: '#B80A1D',
            contrastText: '#fff',
        },
        highlight: '#FBBA01',
        link: '#E30714',
        icon: '#E30714',
    },
    CAR_STORE: {
        ...basePalette,
        primary: {
            light: '#77849f',
            main: '#2d374b',
            dark: '#222938',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ee6e2a',
            main: '#ee6e2a',
            dark: '#db5a15',
            contrastText: '#ffffff',
        },
        highlight: '#ee6e2a',
        link: '#ee6e2a',
        icon: '#ee6e2a',
    },
};
